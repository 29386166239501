@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: "Comfortaa";
  src: url(./fonts/Comfortaa-Light.ttf) format("truetype");
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: "Comfortaa";
  src: url(./fonts/Comfortaa-Regular.ttf) format("truetype");
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: "Comfortaa";
  src: url(./fonts/Comfortaa-Medium.ttf) format("truetype");
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: "Comfortaa";
  src: url(./fonts/Comfortaa-SemiBold.ttf) format("truetype");
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: "Comfortaa";
  src: url(./fonts/Comfortaa-Bold.ttf) format("truetype");
  font-weight: 700;
  font-style: normal;
}

body {
  font-family: "Comfortaa", "Open Sans", sans-serif;
}
* {
  font-family: "Open Sans", sans-serif !important;
}

.normal {
  font-family: "Comfortaa", "Open Sans", sans-serif !important;
}
